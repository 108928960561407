/**
 
Flask App supporting audio/video transcription.

This app runs a flask app with a UI for transcribing audio/video files
to text. It uses Stripe to handle payment processing and AWS DynamoDB
to track status.

Author: J. Langley
Email: jlangley@hsv.ai
Copyright: Copyright 2022, Huntsville AI, LLC
License: No license for this code is granted unless provided in writing

*/

import React, { useRef, useEffect } from 'react';

interface Message {
    text: string,
    timestamp: number,
    start: string,
    end: string
};

interface MessageListProps {
    messages: Message[]
}

function MessageList({ messages }: MessageListProps) {

    const messageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Scroll to the message when it renders
        if (messageRef.current) {
          messageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, [messages]); // Trigger the effect when the message text changes
      
    return (
        <div style={{ height: '300px', overflowY: 'scroll' }}>
            <table className="table table-hover table-dark">
                <thead>
                    <tr>
                        <th scope="col">Time</th>
                        <th scope="col">Text</th>
                    </tr>
                </thead>
                <tbody>
                    {messages.map((message, index) => (
                        <>
                            <tr>
                                <th scope="row">{message.start}</th>
                                <td>{message.text}</td>
                            </tr>
                        </>
                    ))}
                </tbody>
            </table>
            <div ref={messageRef}/>
        </div>
    );
}

export default MessageList;
