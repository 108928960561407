/**
 
Flask App supporting audio/video transcription.

This app runs a flask app with a UI for transcribing audio/video files
to text. It uses Stripe to handle payment processing and AWS DynamoDB
to track status.

Author: J. Langley
Email: jlangley@hsv.ai
Copyright: Copyright 2022, Huntsville AI, LLC
License: No license for this code is granted unless provided in writing

*/

import React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

const Example = () => {

    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <div className="bg-dark bg-gradient py-5" id="examples">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8 col-sm-10 col-xs-12">
                        <h2 className="pb-3">Examples:</h2>
                        <p>
                            Below is a recording of one of our Huntsville AI meetups, followed by the first few 
                            paragraphs of the automatically generated transcription. You can find more videos of
                            our meetups along with transcripts provided by this service at <a target="_blank" rel="noopener noreferrer" href="https://hsv.ai/videos/">https://hsv.ai/videos/</a>
                        </p>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <iframe 
                                src="https://player.vimeo.com/video/1009177758?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                className="bg-dark bg-gradient"
                                allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" 
                                style={{ border: "none" }}
                                title="Faster Whisper"></iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                        <br />
                        <Button hidden={open}
                            onClick={() => setOpen(!open)}
                            aria-controls="collapseExample"
                            aria-expanded={open}
                        >Show Example Transcript</Button>
                        <Button hidden={!open}
                            onClick={() => setOpen(!open)}
                            aria-controls="collapseExample"
                            aria-expanded={open}
                        >Hide Example Transcript</Button>
                        <Collapse in={open} className="py-5">
                            <div className="collapse" id="collapseExample">
                                <p>You can see like the previous sessions we've done this year going back to 2018, but it does get a little sparse the further back you go. When we first started, I think we met like five times the first year and then eventually we got into a rhythm. </p>
                                <p>So what we're talking about tonight is something called Faster Whisper. If you don't know, we actually have a transcription service. And of course, if you are on here and watching this live, if you do drop an auth token, it will let you transcribe stuff for free.</p>
                                <p>So how about it?</p>
                                <p>If I start to lose too much money that way, I will change the auth token.</p>
                                <p>So I also follow my own advice and there is a quota on that budget.</p>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Example;
