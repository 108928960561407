/**
 
Flask App supporting audio/video transcription.

This app runs a flask app with a UI for transcribing audio/video files
to text. It uses Stripe to handle payment processing and AWS DynamoDB
to track status.

Author: J. Langley
Email: jlangley@hsv.ai
Copyright: Copyright 2022, Huntsville AI, LLC
License: No license for this code is granted unless provided in writing

*/

import React from 'react';
import { useEffect, useState } from 'react';

import { useStripe, useElements, Elements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { SocketContext } from "../context/socket";
import ReactGA from "react-ga4";
import { Button, Form, Spinner } from 'react-bootstrap';

const REACT_APP_STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY as string;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);

function ElementWrapper() {
    const stripe = useStripe();
    const elements = useElements();

    const [loading, setLoading] = useState(false);

    const { socket } = React.useContext(SocketContext);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        ReactGA.event({
            action: "SubmitPayment",
            category: "SubmitPayment",
        });

        // Set loading to true to trigger the spinner instead of submit button
        setLoading(true);

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: "https://transcribe.hsv.ai",
            },
            redirect: 'if_required',
        });


        if (result.error) {
            // TODO Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
        } else {
            socket.emit("paymentSuccess");
        }
    };

    return (
        <>
            <h3>3. Pay $5</h3>
            <Form onSubmit={handleSubmit} className="w-100 rounded-1 p-4 border bg-dark" id="payment-form">
                <p>Payment is not finalized until the transcript has been completed and emailed to
                    the address provided. We use Stripe for secure payment processing and do not store
                    payment information.
                </p>
                <PaymentElement />
                <br />
                {loading ? 
                    <Spinner /> : 
                    <Button id="submit" type="submit" className="px-3 rounded-3">
                        Submit Payment Information
                    </Button>
                }
            </Form>
        </>
    );
}

function StripeForm() {
    const [client, setClient] = useState("");

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        axios.post("/create-payment-intent").then((response) => {
            setClient(response.data["clientSecret"]);
        }, (error) => {
            console.log(error);
        });
    };

    return (
        <>
            {client && (
                <Elements stripe={stripePromise} options={{clientSecret: client}}>
                    <ElementWrapper />
                </Elements>
            )}
        </>
    );
}

export default StripeForm;
