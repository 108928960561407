/**
 
Flask App supporting audio/video transcription.

This app runs a flask app with a UI for transcribing audio/video files
to text. It uses Stripe to handle payment processing and AWS DynamoDB
to track status.

Author: J. Langley
Email: jlangley@hsv.ai
Copyright: Copyright 2022, Huntsville AI, LLC
License: No license for this code is granted unless provided in writing

*/

import { Container, Col, Row, Button } from 'react-bootstrap';

const Intro = () => {
    return (
        <Container fluid className="bg-dark bg-gradient">
            <Row className='justify-content-center align-items-center'>
                <Col lg={4} md={6} sm={10} xs={10} className="py-5" >
                    <img width="100%" src="Transcription-Header.png" alt='Robot transcribing an audio file' />
                </Col>
                <Col lg={6} md={8} sm={10} xs={10} className="py-5" >
                    <h2>Automated Transcription Service</h2><hr />
                    <h5>Transcribe your file in three easy steps:</h5>
                    <ol>
                        <li>Enter your email address. We'll send the transcript to you when it completes.</li>
                        <li>Upload your file. Most audio and video formats are accepted.</li>
                        <li>Pay for the service. The first transcription is free, but following transcriptions are $5 each.
                            We charge the same amount to transcribe any file up to 200MB in size.
                        </li>
                    </ol>
                    <a className="float-right" href="#input-section"><Button>Start Now</Button></a>
                </Col>
            </Row>
        </Container>
    );
}

export default Intro;
