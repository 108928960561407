/**
 
Flask App supporting audio/video transcription.

This app runs a flask app with a UI for transcribing audio/video files
to text. It uses Stripe to handle payment processing and AWS DynamoDB
to track status.

Author: J. Langley
Email: jlangley@hsv.ai
Copyright: Copyright 2022, Huntsville AI, LLC
License: No license for this code is granted unless provided in writing

*/

import { SocketContext } from "../context/socket";
import React from 'react';
import { Button, Form } from "react-bootstrap";
import ReactGA from "react-ga4";

type EmailDisplayProps = {
    email: string; 
}

const EmailDisplay = ({ email }: EmailDisplayProps) => {

    const { socket } = React.useContext(SocketContext);

    const clearEmailClick = (event: React.FormEvent) => {
        event.preventDefault();
        ReactGA.event({
            action: "ClearEmail",
            category: "ClearEmail",
        });
        socket.emit("clearEmail");
    };

    return (
        <React.Fragment>
            <h3>1. We will send the transcript to:</h3>
            <Form onSubmit={clearEmailClick} id="clearEmailForm" className="w-100 rounded-1 p-4 border bg-dark">
                <Form.Group>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control readOnly type="email" name="emailInput" id="email" aria-describedby="emailHelp" placeholder={email} />
                    <Form.Text id="emailHelp" className="text-muted">We'll never share your email with anyone else.</Form.Text>
                </Form.Group>
                <Button variant="primary" type="submit">Clear Email</Button>
            </Form>
        </React.Fragment>
    );
}

export default EmailDisplay;
