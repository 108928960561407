/**
 
Flask App supporting audio/video transcription.

This app runs a flask app with a UI for transcribing audio/video files
to text. It uses Stripe to handle payment processing and AWS DynamoDB
to track status.

Author: J. Langley
Email: jlangley@hsv.ai
Copyright: Copyright 2022, Huntsville AI, LLC
License: No license for this code is granted unless provided in writing

*/

import React from 'react';
import { useState, useEffect } from 'react';

import ReactGA from "react-ga4";

import TranscribeNav from './components/TranscribeNav';
import Intro from './components/Intro';
import Details from './components/Details';
import Example from './components/Example';
import About from './components/About';
import InputSection from './components/InputSection';
import { socket, SocketContext } from './context/socket';
import InProgress from './components/InProgress';

const App = () => {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.href, title: "Transcribe" });
    }, []);

    socket.on("update_state", (data) => {
        setEmail(data["email"]);
        setFilename(data["filename"]);
        setPaymentRequired(data["payment_required"]);
        setPaymentSuccess(data["payment_success"]);
        setDuration(data["duration"]);
        setSize(data["size"]);
        setFormat(data["format"]);
        setFileKey(data["file_key"])
    });

    const [email, setEmail] = useState("");
    const [filename, setFilename] = useState("");
    const [fileKey, setFileKey] = useState("");

    const [format, setFormat] = useState("");
    const [duration, setDuration] = useState("");
    const [size, setSize] = useState("");

    const [paymentRequired, setPaymentRequired] = useState(true);
    const [paymentSuccess, setPaymentSuccess] = useState(false);

    return (
        <SocketContext.Provider value={{ socket: socket }}>

            <div className="App">
                <TranscribeNav />
                <Intro />
                {!fileKey && (
                    <>
                        <Details />
                        <InputSection email={email} filename={filename} format={format} duration={duration} size={size} paymentRequired={paymentRequired}
                            paymentSuccess={paymentSuccess} />
                    </>
                )}
                {fileKey && (
                    <InProgress fileKey={fileKey} />
                )}
                <Example />
                <About />
            </div>
        </SocketContext.Provider>
    );
}

export default App;
