/**
 
Flask App supporting audio/video transcription.

This app runs a flask app with a UI for transcribing audio/video files
to text. It uses Stripe to handle payment processing and AWS DynamoDB
to track status.

Author: J. Langley
Email: jlangley@hsv.ai
Copyright: Copyright 2022, Huntsville AI, LLC
License: No license for this code is granted unless provided in writing

*/

import { SocketContext } from "../context/socket";
import React, { useState } from 'react';
import ReactGA from "react-ga4";
import { Button, Form } from "react-bootstrap";

const EmailInput = () => {

    const { socket } = React.useContext(SocketContext);

    const [validated, setValidated] = useState(false);

    const setEmailClick = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const form = event.currentTarget;
        const formElements = form.elements as typeof form.elements & {
            email: HTMLInputElement
        }

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } 
        else {
            ReactGA.event({
                action: "SetEmail",
                category: "SetEmail",
            });
            socket.emit("setEmail", formElements.email.value);
        }
        setValidated(true);

    };

    return (
        <React.Fragment>
            <h3>1. Tell us where to send the transcript</h3>
            <Form noValidate validated={validated} onSubmit={setEmailClick} className="w-100 rounded-1 p-4 border bg-dark">
                <Form.Group>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" name="email" id="email" aria-describedby="emailHelp" 
                        placeholder="Enter email"/>
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid email address.
                    </Form.Control.Feedback>
                    <Form.Text id="emailHelp" className="text-muted">We'll never share your email with anyone else.</Form.Text>
                </Form.Group>
                <Button variant="primary" type="submit">Submit</Button>
            </Form>
        </React.Fragment>
    );
}

export default EmailInput;
