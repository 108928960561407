/**
 
Flask App supporting audio/video transcription.

This app runs a flask app with a UI for transcribing audio/video files
to text. It uses Stripe to handle payment processing and AWS DynamoDB
to track status.

Author: J. Langley
Email: jlangley@hsv.ai
Copyright: Copyright 2022, Huntsville AI, LLC
License: No license for this code is granted unless provided in writing

*/

import React from "react";

const About = () => {

    // URLs used in the about section
    const hsvAiUrl = "https://hsv.ai";
    const contactUrl = "https://hsv.ai/contact/";
    const subscribeUrl = "https://hsv.ai/subscribe/";
    const analyticsUrl = "https://analytics.google.com/";

    return (
        <React.Fragment>
            <div className="bg-dark bg-gradient py-5" id="about">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8 col-sm-10 col-xs-12">
                        <h2 className="pb-3">About Us:</h2>
                        <p>
                        Huntsville AI Transcribe is a service offered by <a href={hsvAiUrl}>
                        Huntsville AI</a>, a community-driven organization dedicated 
                        to advancing artificial intelligence in the Huntsville, 
                        Alabama area. Our transcription service is part of our 
                        broader mission to make AI technologies accessible and 
                        beneficial to everyone. At Huntsville AI, we strive to 
                        build and support the local AI community through collaboration, 
                        education, and practical application. Our goal is to foster 
                        a network of individuals and organizations who work together 
                        to enhance AI knowledge and implementation, ultimately 
                        improving our quality of life. To get involved in our 
                        community, <a href={subscribeUrl}>Subscribe</a> to
                        our bi-weekly mailing list.
                        </p>
                        <h2 className="pb-3">Technical Details:</h2>
                        <p>
                        The transcription service is built around the Whisper model from OpenAI.
                        For each file transcribed, we create an instance of this model inside
                        of a container which is disposed of after the transcript is completed
                        and emailed. We do not keep copies of either the file to be transcribed
                        or the final transcript. We do not use or sell your email or other 
                        data other than to create the requested transcript.
                        </p>
                        <p>
                        It is a bit slower because we're not using a GPU or special hardware,
                        but it also makes it cheaper. Another benefit is that we do not have to 
                        worry about anyone outside of the transcription service having access to 
                        your files or transcripts.
                        </p>
                        <p>
                        If you need a similar transcription service created specifically to 
                        suit your needs, or if you have constraints around where your 
                        data can be stored - we can help. 
                        </p>
                        <p>
                        <a href={contactUrl}>Contact Us</a> with
                        your specific needs and we will reach out.
                        </p>
                        <p>
                        We use <a href={analyticsUrl}>Google Analytics</a> to
                        help us determine how the service is being used and if we have problems
                        with the workflow. There are cookies baked and consumed as part of this process.
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default About;
