/**
 
Flask App supporting audio/video transcription.

This app runs a flask app with a UI for transcribing audio/video files
to text. It uses Stripe to handle payment processing and AWS DynamoDB
to track status.

Author: J. Langley
Email: jlangley@hsv.ai
Copyright: Copyright 2022, Huntsville AI, LLC
License: No license for this code is granted unless provided in writing

*/

import React from 'react';
import StripeForm from './StripeForm';
import { SocketContext } from "../context/socket";
import ReactGA from "react-ga4";
import { Button, Form } from 'react-bootstrap';

type PaymentProps = {
    email: string;
    filename: string;
    paymentRequired: boolean;
    paymentSuccess: boolean;
}

const Payment = ({ email, filename, paymentRequired, paymentSuccess }: PaymentProps) => {

    const { socket } = React.useContext(SocketContext);

    const clearPayment = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        ReactGA.event({
            action: "ClearPayment",
            category: "ClearPayment",
        });
        socket.emit("clearPayment");
    };

    return (
        <React.Fragment>
            {paymentRequired && (!email || !filename) && (
                <>
                    <h3>3. Payment</h3>
                    <Form className="w-100 rounded-1 p-4 border bg-dark">
                        <p>After you enter your email and upload a file, we'll check to see if you qualify
                            for a free transcription. Otherwise you will see a credit card form appear
                            here.
                        </p>
                    </Form>
                </>
            )}
            {paymentRequired && !paymentSuccess && email && filename && (
                <StripeForm />
            )}
            {!paymentRequired && email && filename && (
                <>
                    <h3>3. First Transcription is Free</h3>
                    <Form className="w-100 rounded-1 p-4 border bg-dark">
                        <p>This is the first transcription for {email} and is free of charge.
                        </p>
                    </Form>
                </>
            )}
            {paymentSuccess && (
                <>
                    <h3>3. Payment</h3>
                    <Form className="w-100 rounded-1 p-4 border bg-dark" onSubmit={clearPayment}>
                        <p>Your payment information was received successfully.
                        </p>
                        <Button type="submit">Clear Payment Information</Button>
                    </Form>
                </>
            )}
        </React.Fragment>
    );
}

export default Payment;
