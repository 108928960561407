/**
 
Flask App supporting audio/video transcription.

This app runs a flask app with a UI for transcribing audio/video files
to text. It uses Stripe to handle payment processing and AWS DynamoDB
to track status.

Author: J. Langley
Email: jlangley@hsv.ai
Copyright: Copyright 2022, Huntsville AI, LLC
License: No license for this code is granted unless provided in writing

*/

import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';

const TranscribeNav = () => {
    return (
        <Navbar expand="sm" className="navbar-dark bg-dark">
        <Container>
          <Navbar.Brand href="#">
            <img src="HSVAI_icon05.png" width="30" height="30" className="d-inline-block align-top" alt=""/>
            TRANSCRIBE
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#examples">EXAMPLES</Nav.Link>
              <Nav.Link href="#about">ABOUT US</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      );
}

export default TranscribeNav;
